import React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import logo from "../image/headerimgright.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SignatureCanvas from "../../utils/SignatureCanvas";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import moment from "moment";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { BASE_URL } from "../../global";
import axios from "axios";
import { fetchDepartments } from "../fileManager/fetchHandler";

export default function InternalAuditCorrective() {
  const params = useParams();
  const printComponentRef = React.useRef();
  const [isPrinting, setisPrinting] = React.useState(false);
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);
  const [deptList, setDeptList] = React.useState([]);
  const [formFields, setFormFields] = React.useState({
    reportNumber: "",
    NCNumber: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
    ISOClauseNumber: "",
    department: "",
    auditor: "",
    auditee: "",
    NCType: "",
    auditorsObservation: "",
    auditeeSign: "",
    auditorSign: "",
    correction: "",
    rootCause: "",
    auditeeSign2: "",
    corrrectiveAction: "",
    auditeeSign3: "",
    commentsByAuditor: "",
    satisfactionType: "",
    auditorSign2: "",
    auditorSignDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const refresh = () => {
    window.location.reload(false);
  };

  const fetchDepartment = async () => {
    try {
      const filesData = await fetchDepartments();
      setDeptList(filesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = printComponentRef.current;

      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [10, 1, 25, 1],
          filename: `audit.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("helvetica", "bold");

        const borderWidth = 0.6;
        const leftMargin = 10.7;
        const borderX = leftMargin;
        const borderY = 10;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 805);

        let image = await generateDynamicFooter(i, totalPages);
        pdf.addImage(image, 0, 810, 595, 40);
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${formFields?.reportNumber+"_"+formFields?.NCNumber+"_"+ formFields?.department }.pdf`;
      a.click();
      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  const handleChange = (field, value) => {
    setFormFields((prevFields) => ({ ...prevFields, [field]: value }));
  };
  const handleCheckboxChange = (field, value) => {
    const currentValues = (formFields[field] || "").split(",");
    if (currentValues?.includes(value)) {
      const updatedValues = currentValues?.filter((v) => v !== value);
      handleChange(field, updatedValues.join(","));
    } else {
      const updatedValues = [...currentValues, value];
      handleChange(field, updatedValues.join(","));
    }
  };

  const handleSubmit = () => {
    const invalidFields = [];
    if (!formFields.reportNumber) invalidFields.push("Report Number");

    if (invalidFields.length > 0) {
      toast.error(
        `Please fill in the following fields: ${invalidFields.join(", ")}`
      );
      return;
    }
    
    const exceededFields = [];
    // Length validation
    if (formFields?.auditorsObservation?.length > 3000) exceededFields.push("Auditors Observation");
    if (formFields?.correction?.length > 1500) exceededFields.push("Correction");
    if (formFields?.corrrectiveAction?.length > 1500) exceededFields.push("Corrective Action");
    if (formFields?.rootCause?.length > 1500) exceededFields.push("Root Cause");
    if (formFields?.commentsByAuditor?.length > 500) exceededFields.push("Comments By Auditor");
      // Show warning message for fields exceeding length limits
      if (exceededFields.length > 0) {
        toast.warn(`The following records exceed the maximum length: ${exceededFields.join(", ")}`);
        return;
      } 

    let payload = {
      reportNumber: formFields.reportNumber,
      NCNumber: formFields.NCNumber,
      date: moment(formFields.date).format("YYYY-MM-DD"),
      ISOClauseNumber: formFields.ISOClauseNumber,
      department: formFields.department,
      auditor: formFields.auditor,
      auditee: formFields.auditee,
      NCType: formFields.NCType,
      auditorsObservation: formFields.auditorsObservation,
      auditeeSign: formFields.auditeeSign,
      auditorSign: formFields.auditorSign,
      correction: formFields.correction,
      rootCause: formFields.rootCause,
      auditeeSign2: formFields.auditeeSign2,
      corrrectiveAction: formFields.corrrectiveAction,
      auditeeSign3: formFields.auditeeSign3,
      commentsByAuditor: formFields.commentsByAuditor,
      satisfactionType: formFields.satisfactionType,
      auditorSign2: formFields.auditorSign2,
      auditorSignDate: moment(formFields.auditorSignDate).format("YYYY-MM-DD"),
    };
    // console.log("Form Values:", payload);
    if (params.id != 0) {
      axios
        .patch(
          BASE_URL + `internalAuditCorrectiveActions/${params.id}`,
          payload
        )
        .then((res) => {
          toast.success(`Audit Correct Action Update Successfully`);
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(BASE_URL + "internalAuditCorrectiveActions", payload)
        .then((res) => {
          toast.success(`Audit Correct Action Saved Successfully`);
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fetchInternalAuditData = async () => {
    try {
      const interanlAuditResponse = await axios.get(
        BASE_URL + `internalAuditCorrectiveActions/${params.id}`
      );
      setFormFields({
        reportNumber: interanlAuditResponse.data[0]?.reportNumber,
        NCNumber: interanlAuditResponse.data[0]?.NCNumber,
        date: moment(interanlAuditResponse.data[0]?.date).format("YYYY-MM-DD"),
        ISOClauseNumber: interanlAuditResponse.data[0]?.ISOClauseNumber,
        department: interanlAuditResponse.data[0]?.department,
        auditor: interanlAuditResponse.data[0]?.auditor,
        auditee: interanlAuditResponse.data[0]?.auditee,
        NCType: interanlAuditResponse.data[0]?.NCType,
        auditorsObservation: interanlAuditResponse.data[0]?.auditorsObservation,
        auditeeSign: interanlAuditResponse.data[0]?.auditeeSign,
        auditorSign: interanlAuditResponse.data[0]?.auditorSign,
        correction: interanlAuditResponse.data[0]?.correction,
        rootCause: interanlAuditResponse.data[0]?.rootCause,
        auditeeSign2: interanlAuditResponse.data[0]?.auditeeSign2,
        corrrectiveAction: interanlAuditResponse.data[0]?.corrrectiveAction,
        auditeeSign3: interanlAuditResponse.data[0]?.auditeeSign3,
        commentsByAuditor: interanlAuditResponse.data[0]?.commentsByAuditor,
        satisfactionType: interanlAuditResponse.data[0]?.satisfactionType,
        auditorSign2: interanlAuditResponse.data[0]?.auditorSign2,
        auditorSignDate: moment(
          interanlAuditResponse.data[0]?.auditorSignDate
        ).format("YYYY-MM-DD"),
      });
    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    if (params.id !== 0) {
      fetchInternalAuditData();
    }
  }, [params.id]);

  React.useEffect(() => {
    fetchDepartment();
  }, []);

  const printView = () => {
    return (
      <div
        style={{ width: "97%", margin: "auto", fontSize: "1rem" }}
        ref={printComponentRef}
      >
        <div
          id="header-section"
          style={{ width: "100%", margin: "auto" }}
        ></div>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            tableLayout: "fixed",
            fontSize: "11px",
          }}
        >
          <tr>
            <th
              style={{
                // border: "1px solid black",
                textAlign: "center",
                padding: "0",
                borderBottom: "none",
                // width: "30%",
              }}
              colSpan={"2"}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0",
                }}
              >
                <div style={{ borderRight: "1px solid black", width: "50%" }}>
                  <img
                    src={logo}
                    alt="logo"
                    style={{ height: "60px", margin: "5px" }}
                  />
                </div>
                <div style={{ textAlign: "center", width: "50%" }}>
                  <h5>
                    <b>Internal Audit Observation Sheet</b>
                  </h5>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th
              colSpan={"2"}
              style={{
                border: "1px solid black",
                padding: "5px",
                width: "50%",
                borderBottom: "none",
                textAlign: "right",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  justifyContent: "flex-end",
                }}
              >
                <b>Doc No:</b>
                <span>SEIPL/F/06</span>
              </Box>
            </th>
          </tr>
          <tr>
            <th
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "5px",
                width: "50%",
                borderBottom: "none",
                borderRight: "none",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <b>Repot No:</b>
                <span>{formFields?.reportNumber}</span>
              </Box>
            </th>
            <th
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "5px",
                width: "50%",
                borderBottom: "none",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <b>Date:</b>
                <span>{moment(formFields?.date).format("DD-MM-YYYY")}</span>
              </Box>
            </th>
          </tr>
          <tr>
            <th
              style={{
                border: "1px solid black",
                textAlign: "left",
                width: "50%",
                padding: "5px",
                borderRight: "none",
                borderBottom: "none",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <b>NC No:</b>
                <span>{formFields?.NCNumber}</span>
              </Box>
            </th>
            <th
              style={{
                border: "1px solid black",
                textAlign: "left",
                width: "50%",
                padding: "5px",
                borderBottom: "none",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <b> ISO Clause NO:</b>
                <span>{formFields?.ISOClauseNumber}</span>
              </Box>
            </th>
          </tr>
          <tr>
            <th
              colSpan={"2"}
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "5px",
                borderBottom: "none",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <b>Department:</b>
                <span>{formFields?.department}</span>
              </Box>
            </th>
          </tr>
          <tr>
            <th
              colSpan="2"
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "5px",
                borderBottom: "none",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <b>Auditor:</b>
                <span>{formFields?.auditor}</span>
              </Box>
            </th>
          </tr>
          <tr>
            <th
              colSpan="2"
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "5px",
                borderBottom: "none",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <b>Auditee:</b>
                <span>{formFields?.auditee}</span>
              </Box>
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              colSpan="2"
              style={{ border: "1px solid black", borderBottom: "none" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    width: "33.33%",
                    borderRight: "1px solid black",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox checked={formFields?.NCType?.includes("1")} />
                    }
                    label={"Major NC"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    width: "33.33%",
                    borderRight: "1px solid black",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox checked={formFields?.NCType?.includes("2")} />
                    }
                    label={"Minor NC"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    width: "33.33%",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox checked={formFields?.NCType?.includes("3")} />
                    }
                    label={"OFI"}
                  />
                </div>
              </div>
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              colSpan="2"
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "8px",
                borderBottom: "none",
              }}
            >
              Auditor’s Observation:
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <span style={{ width: "100%", wordWrap: "break-word" }}>
                  {formFields?.auditorsObservation}
                </span>
              </Box>
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "8px",
                width: "50%",
                borderRight: "none",
                borderBottom: "none",
              }}
            >
              Sign (Auditee):
              <img
                src={formFields.auditeeSign}
                style={{ height: "80px", width: "150px" }}
              />
            </th>
            <th
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "8px",
                width: "50%",
                borderBottom: "none",
              }}
            >
              Sign (Auditor):
              <img
                src={formFields.auditorSign}
                style={{ height: "80px", width: "150px" }}
              />
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              colSpan="2"
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "8px",
                borderBottom: "none",
              }}
            >
              Correction:
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <span style={{ width: "100%", wordWrap: "break-word" }}>
                  {formFields?.correction}
                </span>
              </Box>
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              colSpan="2"
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "8px",
                borderBottom: "none",
              }}
            >
              Root Cause:
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <span style={{ width: "100%", wordWrap: "break-word" }}>
                  {formFields?.rootCause}
                </span>
              </Box>
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              colSpan="2"
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "8px",
                borderBottom: "none",
              }}
            >
              Sign (Auditee):
              <img
                src={formFields.auditeeSign2}
                style={{ height: "80px", width: "150px" }}
              />
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              colSpan="2"
              style={{
                border: "1px solid black",
                textAlign: "left",
                borderBottom: "none",
                padding: "8px",
              }}
            >
              Corrective Action:
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <span style={{ width: "100%", wordWrap: "break-word" }}>
                  {formFields?.corrrectiveAction}
                </span>
              </Box>
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              colSpan="2"
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "8px",
                borderBottom: "none",
              }}
            >
              Sign (Auditee):
              <img
                src={formFields.auditeeSign3}
                style={{ height: "80px", width: "150px" }}
              />
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              colSpan="2"
              style={{
                border: "1px solid black",
                textAlign: "left",
                borderBottom: "none",
                padding: "8px",
              }}
            >
              <b>Comments by Auditor:</b>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <span style={{ width: "100%", wordWrap: "break-word" }}>
                  {formFields?.commentsByAuditor}
                </span>
              </Box>
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              colSpan="2"
              style={{ border: "1px solid black", borderBottom: "none" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    width: "30%",
                    borderRight: "1px solid black",
                  }}
                >
                  <Checkbox
                    style={{ width: "25px" }}
                    checked={formFields?.satisfactionType?.includes("1")}
                  />
                  <span style={{ fontSize: "12px" }}>Satisfactory</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    width: "30%",
                    borderRight: "1px solid black",
                  }}
                >
                  <Checkbox
                    style={{ width: "25px" }}
                    checked={formFields?.satisfactionType?.includes("2")}
                  />
                  <span style={{ fontSize: "12px" }}>Unsatisfactory</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    width: "40%",
                  }}
                >
                  <Checkbox
                    style={{ width: "25px" }}
                    checked={formFields?.satisfactionType?.includes("3")}
                  />
                  <span style={{ fontSize: "12px" }}>
                    To be review during next audit
                  </span>
                </div>
              </div>
            </th>
          </tr>
          <tr id="pagbreaksborder">
            <th
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "8px",
                width: "50%",
                borderRight: "none",
              }}
            >
              <span>Sign (Auditor):</span>
              <img
                src={formFields.auditorSign2}
                style={{ height: "80px", width: "150px" }}
              />
            </th>
            <th
              style={{
                border: "1px solid black",
                textAlign: "left",
                padding: "8px",
                width: "50%",
              }}
            >
              Date:
              <span>
                {moment(formFields?.auditorSignDate).format("DD-MM-YYYY")}
              </span>
            </th>
          </tr>
        </table>
      </div>
    );
  };

  const loadingModal = () => {
    return (
      <Modal open={downloadPdfloading}>
        <Box
          style={{
            width: "100%",
            height: "100vh",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div>
            <CircularProgress thickness={5} color="primary" size="6rem" />
            <h5
              style={{ color: "white", fontWeight: "900", fontSize: "2.5rem" }}
            >
              Pdf Download In Progress...
            </h5>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        margin: "0px auto",
        padding: "0px 10px",
        fontSize: "13px",
      }}
    >
      {isPrinting && (
        <Grid
          container
          spacing={2}
          align="left"
          sx={{ width: "90%", m: "auto" }}
        >
          <Grid item xs={6} sm={6} md={6} sx={{ margin: "20px 0px" }}>
            <Button
              style={{ backgroundColor: "grey" }}
              variant="contained"
              size="small"
              onClick={() => {
                setisPrinting(false);
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            sx={{ margin: "20px 0px", textAlign: "right" }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                generatePDF();
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
      )}
      {!isPrinting && (
        <Box style={{ width: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              tableLayout: "fixed",
            }}
          >
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  padding: "0",
                  // width: "30%",
                }}
                colSpan={"2"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0",
                  }}
                >
                  <div style={{ borderRight: "1px solid black", width: "50%" }}>
                    <img src={logo} alt="logo" style={{ height: "70px" }} />
                  </div>
                  <div style={{ textAlign: "center", width: "50%" }}>
                    <h5>
                      <b>Internal Audit Corrective Action Report</b>
                    </h5>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th
                colSpan={"2"}
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  width: "50%",
                  textAlign: "right",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "flex-end",
                  }}
                >
                  <b>Doc No:</b>
                  SEIPL/F/06
                </Box>
              </th>
            </tr>
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "5px",
                  width: "50%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <b style={{ width: "20%" }}>Repot No:</b>
                  <TextField
                    sx={{ width: "80%", m: "auto" }}
                    rows={1.5}
                    multiline
                    maxRows={5}
                    size="small"
                    name="reportNumber"
                    variant="outlined"
                    value={formFields.reportNumber}
                    onChange={(e) =>
                      handleChange("reportNumber", e.target.value)
                    }
                  />
                </Box>
              </th>
              <th
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "5px",
                  width: "50%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    height: "50px",
                  }}
                >
                  <b style={{ width: "20%" }}>Date:</b>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      slotProps={{ textField: { size: "small" } }}
                      label="Date *"
                      inputFormat="dd/MM/yyyy"
                      format="dd/MM/yyyy"
                      value={formFields.date ? new Date(formFields.date) : ""}
                      onChange={(date) => handleChange("date", date)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </th>
            </tr>
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  width: "50%",
                  padding: "5px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <b style={{ width: "20%" }}>NC No:</b>
                  <TextField
                    sx={{ width: "80%", m: "auto" }}
                    rows={1.5}
                    multiline
                    maxRows={5}
                    size="small"
                    name="NCNumber"
                    variant="outlined"
                    value={formFields.NCNumber}
                    onChange={(e) => handleChange("NCNumber", e.target.value)}
                  />
                </Box>
              </th>
              <th
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  width: "50%",
                  padding: "5px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <b style={{ width: "20%" }}> ISO Clause NO:</b>
                  <TextField
                    sx={{ width: "80%", m: "auto" }}
                    rows={1.5}
                    multiline
                    maxRows={5}
                    size="small"
                    name="ISOClauseNumber"
                    variant="outlined"
                    value={formFields.ISOClauseNumber}
                    onChange={(e) =>
                      handleChange("ISOClauseNumber", e.target.value)
                    }
                  />
                </Box>
              </th>
            </tr>
            <tr>
              <th
                colSpan={"2"}
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <b style={{ width: "20%" }}>Department:</b>
                  <FormControl sx={{ width: "80%", m: "auto" }}>
                    <InputLabel id="demo-simple-select-label">
                      Department
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formFields.department}
                      label="Department"
                      onChange={(e) =>
                        handleChange("department", e.target.value)
                      }
                    >
                      {deptList?.map((el) => (
                        <MenuItem value={el.name}>{el.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <b style={{ width: "20%" }}>Auditor:</b>
                  <TextField
                    sx={{ width: "80%", m: "auto" }}
                    rows={1.5}
                    multiline
                    maxRows={5}
                    size="small"
                    name="auditor"
                    variant="outlined"
                    value={formFields.auditor}
                    onChange={(e) => handleChange("auditor", e.target.value)}
                  />
                </Box>
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <b style={{ width: "20%" }}>Auditee:</b>
                  <TextField
                    sx={{ width: "80%", m: "auto" }}
                    rows={1.5}
                    multiline
                    maxRows={5}
                    size="small"
                    name="auditee"
                    variant="outlined"
                    value={formFields.auditee}
                    onChange={(e) => handleChange("auditee", e.target.value)}
                  />
                </Box>
              </th>
            </tr>
            <tr>
              <th colSpan="2" style={{ border: "1px solid black" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ width: "33.33%", borderRight: "1px solid black" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields?.NCType?.includes("1")}
                          onChange={() => handleCheckboxChange("NCType", "1")}
                        />
                      }
                      label={"Major NC"}
                    />
                  </div>
                  <div
                    style={{ width: "33.33%", borderRight: "1px solid black" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields?.NCType?.includes("2")}
                          onChange={() => handleCheckboxChange("NCType", "2")}
                        />
                      }
                      label={"Minor NC"}
                    />
                  </div>
                  <div style={{ width: "33.33%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields?.NCType?.includes("3")}
                          onChange={() => handleCheckboxChange("NCType", "3")}
                        />
                      }
                      label={"OFI"}
                    />
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Auditor’s Observation:
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <TextField
                    sx={{ width: "100%", m: "auto" }}
                    rows={2}
                    multiline
                    maxRows={5}
                    size="small"
                    name="auditorsObservation"
                    variant="outlined"
                    value={formFields.auditorsObservation}
                    onChange={(e) =>
                      handleChange("auditorsObservation", e.target.value)
                    }
                  />
                </Box>
              </th>
            </tr>
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                  width: "50%",
                }}
              >
                Sign (Auditee):
                <SignatureCanvas
                  fieldName="auditeeSign"
                  updateUrl={(url) => handleChange("auditeeSign", url)}
                  url={formFields.auditeeSign}
                />
              </th>
              <th
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                  width: "50%",
                }}
              >
                Sign (Auditor):
                <SignatureCanvas
                  fieldName="auditorSign"
                  updateUrl={(url) => handleChange("auditorSign", url)}
                  url={formFields.auditorSign}
                />
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Correction:
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <TextField
                    rows={2}
                    multiline
                    maxRows={5}
                    sx={{ width: "100%" }}
                    size="small"
                    name="correction"
                    variant="outlined"
                    value={formFields.correction}
                    onChange={(e) => handleChange("correction", e.target.value)}
                  />
                </Box>
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Root Cause:
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <TextField
                    rows={2}
                    multiline
                    maxRows={5}
                    sx={{ width: "100%" }}
                    size="small"
                    name="rootCause"
                    variant="outlined"
                    value={formFields.rootCause}
                    onChange={(e) => handleChange("rootCause", e.target.value)}
                  />
                </Box>
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Sign (Auditee):
                <SignatureCanvas
                  fieldName="auditeeSign2"
                  updateUrl={(url) => handleChange("auditeeSign2", url)}
                  url={formFields.auditeeSign2}
                />
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Corrective Action:
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <TextField
                    rows={2}
                    multiline
                    maxRows={5}
                    sx={{ width: "100%" }}
                    size="small"
                    name="corrrectiveAction"
                    variant="outlined"
                    value={formFields.corrrectiveAction}
                    onChange={(e) =>
                      handleChange("corrrectiveAction", e.target.value)
                    }
                  />
                </Box>
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Sign (Auditee):
                <SignatureCanvas
                  fieldName="auditeeSign3"
                  updateUrl={(url) => handleChange("auditeeSign3", url)}
                  url={formFields.auditeeSign3}
                />
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <b style={{ width: "20%" }}>Comments by Auditor:</b>
                  <TextField
                    sx={{ width: "80%", m: "auto" }}
                    rows={2}
                    multiline
                    maxRows={5}
                    size="small"
                    name="commentsByAuditor"
                    variant="outlined"
                    value={formFields.commentsByAuditor}
                    onChange={(e) =>
                      handleChange("commentsByAuditor", e.target.value)
                    }
                  />
                </Box>
              </th>
            </tr>
            <tr>
              <th colSpan="2" style={{ border: "1px solid black" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      width: "30%",
                      borderRight: "1px solid black",
                    }}
                  >
                    <Checkbox
                      style={{ width: "25px" }}
                      checked={formFields?.satisfactionType?.includes("1")}
                      onChange={() =>
                        handleCheckboxChange("satisfactionType", "1")
                      }
                    />
                    <span style={{ fontSize: "12px" }}>Satisfactory</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      width: "30%",
                      borderRight: "1px solid black",
                    }}
                  >
                    <Checkbox
                      style={{ width: "25px" }}
                      checked={formFields?.satisfactionType?.includes("2")}
                      onChange={() =>
                        handleCheckboxChange("satisfactionType", "2")
                      }
                    />
                    <span style={{ fontSize: "12px" }}>Unsatisfactory</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      width: "40%",
                    }}
                  >
                    <Checkbox
                      style={{ width: "25px" }}
                      checked={formFields?.satisfactionType?.includes("3")}
                      onChange={() =>
                        handleCheckboxChange("satisfactionType", "3")
                      }
                    />
                    <span style={{ fontSize: "12px" }}>
                      To be review during next audit
                    </span>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                  width: "50%",
                }}
              >
                Sign (Auditor):
                <SignatureCanvas
                  fieldName="auditorSign2"
                  updateUrl={(url) => handleChange("auditorSign2", url)}
                  url={formFields.auditorSign2}
                />
              </th>
              <th
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "8px",
                  width: "50%",
                }}
              >
                Date:
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    marginTop: "10px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      slotProps={{ textField: { size: "small" } }}
                      label="Date *"
                      inputFormat="dd/MM/yyyy"
                      format="dd/MM/yyyy"
                      value={
                        formFields.auditorSignDate
                          ? new Date(formFields.auditorSignDate)
                          : ""
                      }
                      onChange={(date) => handleChange("auditorSignDate", date)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </th>
            </tr>
          </table>
        </Box>
      )}
      {isPrinting && printView()}

      {!isPrinting && (
        <Grid container spacing={1} align="left">
          {params.id != 0 && (
            <Grid item xs={3} sm={3} md={1} sx={{ margin: "20px 0px" }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setisPrinting(!isPrinting);
                }}
              >
                Print
              </Button>
            </Grid>
          )}
          <Grid item xs={3} sm={3} md={1} sx={{ margin: "20px 0px" }}>
            <Button
              style={{ backgroundColor: "grey" }}
              variant="contained"
              size="small"
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={3} sm={3} md={1} sx={{ margin: "20px 0px" }}>
            <Button variant="contained" size="small" onClick={handleSubmit}>
              {params.id == 0 ? `Submit` : `Update`}
            </Button>
          </Grid>
        </Grid>
      )}
      {downloadPdfloading && loadingModal()}
    </div>
  );
}
