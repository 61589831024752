import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import logo from "../image/headerimgright.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import React from "react";
import moment from "moment";
import { BASE_URL } from "../../global";
import axios from "axios";
import { useParams } from "react-router";
import { fetchDepartments } from "../fileManager/fetchHandler";
import { toast } from "react-toastify";

export default function InternalAuditObservation() {
  const params = useParams();
  const printComponentRef = React.useRef();
  const [isPrinting, setisPrinting] = React.useState(false);
  const [functionRows, setFunctionRows] = React.useState([]);
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);
  const [deptList, setDeptList] = React.useState([]);
  const [auditData, setAuditData] = React.useState({
    IQANumber: "",
    auditor: "",
    auditee: "",
    functionActivity: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
  });
  const refresh = () => {
    window.location.reload(false);
  };

  const fetchDepartment = async () => {
    try {
      const filesData = await fetchDepartments();
      setDeptList(filesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAuditDataChange = (field, value) => {
    setAuditData({
      ...auditData,
      [field]: value,
    });
  };

  const handleAddRow = () => {
    setFunctionRows([
      ...functionRows,
      { claueNumber: "", recordsObservation: "", CType: "" },
    ]);
  };
  const handleChange = (index, field, value) => {
    const updatedRows = [...functionRows];
    updatedRows[index][field] = value;
    setFunctionRows(updatedRows);
  };
  const handleDeleteRow = (index) => {
    setFunctionRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
  };

  const handleSubmit = () => {
    const invalidFields = [];
    if (!auditData.IQANumber) invalidFields.push("IQA Number");
    if (!auditData.auditor) invalidFields.push("Auditor");
    if (!auditData.auditee) invalidFields.push("Auditee");
    if (invalidFields.length > 0) {
      toast.error(
        `Please fill in the following fields: ${invalidFields.join(", ")}`
      );
      return;
    }
    const hasEmptyFields = functionRows.some((row) => {
      return !row.claueNumber || !row.recordsObservation || row.CType === "";
    });

    if (hasEmptyFields) {
      toast.error("Please fill in all fields for each row.");
      return;
    }

    const tooLongFields = functionRows.filter(row => row.recordsObservation.length > 3000);
    if (tooLongFields.length > 0) {
        toast.warn(`The following records exceed the maximum length: ${tooLongFields.map(row => `Clause No. ${row?.claueNumber}`).join(', ')}.`);
        return;
    }

    let auditDatapayload = {
      IQANumber: auditData.IQANumber,
      auditor: auditData.auditor,
      auditee: auditData.auditee,
      functionActivity: auditData.functionActivity,
      date: moment(auditData.date).format("YYYY-MM-DD"),
    };

    if (params.id != 0) {
      axios
        .patch(
          BASE_URL + `internalAuditObservation/${params.id}`,
          auditDatapayload
        )
        .then(async (res) => {
          const observationRecordsData = functionRows.map((row) => ({
            auditObservationId: params.id,
            claueNumber: row.claueNumber,
            recordObservation: row.recordsObservation,
            CType: row.CType,
          }));
          let deleteobservation = {
            query: `DELETE FROM observationRecords WHERE auditObservationId = ${params.id}`,
          };
          await axios.post(BASE_URL + `dynamic`, deleteobservation);
          if( observationRecordsData.length > 0 ){
          await axios
            .post(BASE_URL + "observationRecords/bulk", observationRecordsData)
            .then((res) => {
              toast.success(`Audit Observation Updated Successfully`);
            })
            .catch((error) => {
              console.log(error);
            });
          }
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(BASE_URL + "internalAuditObservation", auditDatapayload)
        .then((res) => {
          const observationRecordsData = functionRows.map((row) => ({
            auditObservationId: res.data.insertId,
            claueNumber: row.claueNumber,
            recordObservation: row.recordsObservation,
            CType: row.CType,
          }));
          if( observationRecordsData.length > 0 ){
          axios
            .post(BASE_URL + "observationRecords/bulk", observationRecordsData)
            .then((res) => {
              toast.success(`Audit Observation Saved Successfully`);
            })
            .catch((error) => {
              console.log(error);
            });
          }
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const generateDynamicHeader = async () => {
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <th style="border: 1px solid black; text-align: center; padding: 5px; border-right: none; border-bottom: none;">
          <img src=${logo} alt="logo" style="height: 65px; width: 80px;">
        </th>
        <th style="border: 1px solid black; text-align: center; padding: 5px; border-right: none; border-bottom: none;">
          <h5><b>Internal Audit Observation Sheet</b></h5>
        </th>
        <th style="border: 1px solid black; text-align: center; padding: 5px; border-bottom: none;">
          <h6><b> Doc No: SEIPL/F/04</b></h6>
        </th>
      </tr>
      <tr>
        <th style="border: 1px solid black; text-align: left; padding: 5px; border-right: none; border-right: none; border-bottom: none;">
          <b>IQA No. ${auditData?.IQANumber}</b>
        </th>
        <th style="border: 1px solid black; text-align: center; padding: 5px; border-right: none; border-left: none; border-bottom: none;">
          <b> Auditor: ${auditData?.auditor}</b>
        </th>
        <th style="border: 1px solid black; text-align: center; border-left: none; padding: 5px; border-bottom: none;"></th>
      </tr>
      <tr>
        <th style="border: 1px solid black; text-align: left; padding: 5px; border-right: none; border-bottom: none;">
          <b>Date: ${moment(auditData?.date).format("DD-MM-YYYY")}</b>
        </th>
        <th style="border: 1px solid black; text-align: center; padding: 5px; border-right: none; border-left: none; border-bottom: none;">
          <b>Auditee: ${auditData?.auditee}</b>
        </th>
        <th style="border: 1px solid black; text-align: center; padding: 5px; border-left: none; border-bottom: none;"></th>
      </tr>
      <tr>
        <th colspan="3" style="border: 1px solid black; text-align: left; padding: 5px; border-bottom: none;">
          <b>Function / Activity : ${auditData?.functionActivity}</b>
        </th>
      </tr>
      </table>
      <table style="width:100%;">
      <tr>
        <th style="border: 1px solid black; text-align: center; padding: 5px 0px; border-right: none; width:12%;">
          <b>Cluase No.</b>
        </th>
        <th style="border: 1px solid black; text-align: center; padding: 5px; border-right: none; width:68%;">
          <b>Records Observation</b>
        </th>
        <th style="border: 1px solid black; text-align: center; padding: 5px; width:20%;">
          <b>C / NC / Min NC / OFI</b>
        </th>
      </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
      <tr>
          <td style="border: 1px solid black;width:12%;padding:5px;font-family: Calibri, sans-serif;text-align:center;">
            <b style="font-size:20px;">C - Confirmity	</b>
          </td>
          <td style="border: 1px solid black;width:68%;padding:5px;font-family: Calibri, sans-serif;text-align:center;">
            <b style="font-size:20px;">NC - Minor / Major Non Confirmity</b>
          </td>
          <td style="border: 1px solid black;width:20%;padding:5px;font-family: Calibri, sans-serif;text-align:center;">
            <b style="font-size:20px;">OFI-Opportunity for Improvement</b>
          </td>
        </tr>
        <tr>
          <td colspan="3" style="border:none;padding:5px;font-family: Calibri, sans-serif;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = printComponentRef.current;

      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [159, 1, 55, 1],
          filename: `audit.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("helvetica", "bold");

        let image = await generateDynamicHeader();
        pdf.addImage(image, 0, 0, 595, 170);

        image = await generateDynamicFooter(i, totalPages);
        pdf.addImage(image, 0, 775, 595, 80);
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${auditData?.IQANumber}.pdf`;
      a.click();

      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };
  const printView = () => {
    return (
      <Box
        style={{ width: "95%", margin: "auto", fontSize: "1rem" }}
        ref={printComponentRef}
      >
        <div
          id="header-section"
          style={{ width: "100%", margin: "auto" }}
        ></div>
        <table
          style={{
            tableLayout: "fixed",
            borderCollapse: "collapse",
            width: "100%",
            fontSize: "11px",
          }}
        >
          <tbody>
            {functionRows.map((row, index) => (
              <tr id="pagbreaksborder">
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    padding: "5px",
                    borderRight: "none",
                    borderBottom: "none",
                    width: "12%",
                  }}
                >
                  <b style={{ width: "100%", wordWrap: "break-word" }}>
                    {row.claueNumber}
                  </b>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "left",
                    padding: "5px",
                    borderRight: "none",
                    borderBottom: "none",
                    width: "68%",
                  }}
                >
                  <b style={{ width: "100%", wordWrap: "break-word" }}>
                    {row.recordsObservation}
                  </b>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    padding: "5px",
                    borderBottom: "none",
                    width: "20%",
                  }}
                >
                  <b>
                    {row.CType === 1
                      ? "C"
                      : row.CType === 2
                      ? "NC"
                      : row.CType === 3
                      ? "Min NC"
                      : row.CType === 4
                      ? "OFI"
                      : null}
                  </b>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <th
              style={{
                border: "1px solid black",
                textAlign: "center",
                borderTop: "none",
                borderRight: "none",
              }}
            ></th>
            <th
              style={{
                border: "1px solid black",
                textAlign: "center",
                borderTop: "none",
                borderRight: "none",
              }}
            ></th>
            <th
              style={{
                border: "1px solid black",
                textAlign: "center",
                borderTop: "none",
              }}
            ></th>
          </tfoot>
        </table>
      </Box>
    );
  };

  const fetchObservationData = async () => {
    try {
      const auditDataresponse = await axios.get(
        BASE_URL + `internalAuditObservation/${params.id}`
      );
      setAuditData({
        IQANumber: auditDataresponse.data[0]?.IQANumber || "",
        auditor: auditDataresponse.data[0]?.auditor || "",
        auditee: auditDataresponse.data[0]?.auditee || "",
        functionActivity: auditDataresponse.data[0]?.functionActivity || "",
        date:
          moment(auditDataresponse.data[0]?.date).format("YYYY-MM-DD") || "",
      });

      const observationQuery = {
        query: `SELECT * FROM observationRecords WHERE auditObservationId = ${params.id}`,
      };
      const auditObservationresponse = await axios.post(
        BASE_URL + `dynamic`,
        observationQuery
      );

      const mappedRows = auditObservationresponse.data?.map((row) => ({
        claueNumber: row.claueNumber,
        recordsObservation: row.recordObservation,
        CType: row.CType,
      }));

      setFunctionRows(mappedRows);
    } catch (error) {
      console.error(error);
    }
  };
  const loadingModal = () => {
    return (
      <Modal open={downloadPdfloading}>
        <Box
          style={{
            width: "100%",
            height: "100vh",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div>
            <CircularProgress thickness={5} color="primary" size="6rem" />
            <h5
              style={{ color: "white", fontWeight: "900", fontSize: "2.5rem" }}
            >
              Pdf Download In Progress...
            </h5>
          </div>
        </Box>
      </Modal>
    );
  };
  React.useEffect(() => {
    if (params.id !== 0) {
      fetchObservationData();
    }
  }, [params.id]);

  React.useEffect(() => {
    fetchDepartment();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        margin: "0px auto",
        padding: "0px 10px",
        fontSize: "13px",
      }}
    >
      {isPrinting && (
        <Grid
          container
          spacing={2}
          align="left"
          sx={{ width: "90%", m: "auto" }}
        >
          <Grid item xs={6} sm={6} md={6} sx={{ margin: "20px 0px" }}>
            <Button
              style={{ backgroundColor: "grey" }}
              variant="contained"
              size="small"
              onClick={() => {
                setisPrinting(false);
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            sx={{ margin: "20px 0px", textAlign: "right" }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                generatePDF();
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
      )}
      {!isPrinting && (
        <div
          style={{
            width: "100%",
            margin: "20px auto",
            padding: "0px 10px",
            fontSize: "13px",
          }}
        >
          <Box style={{ width: "100%" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    <img src={logo} alt="logo" style={{ height: "50px" }} />
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    <h5>
                      <b style={{ fontSize: "65%" }}>
                        Internal Audit Observation Sheet
                      </b>
                    </h5>
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <h6>
                        <b> Doc No.</b>
                      </h6>
                      SEIPL/F/04
                    </Box>
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "left",
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <b>IQA No.</b>
                      <TextField
                        sx={{ width: "50%", m: "auto" }}
                        rows={1.5}
                        multiline
                        maxRows={5}
                        size="small"
                        name="IQANumber"
                        variant="outlined"
                        value={auditData.IQANumber}
                        onChange={(e) =>
                          handleAuditDataChange("IQANumber", e.target.value)
                        }
                      />
                    </Box>
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                      borderRight: "none",
                      borderLeft: "none",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <b>Auditor</b>
                      <TextField
                        sx={{ width: "60%", m: "auto" }}
                        rows={1.5}
                        multiline
                        maxRows={5}
                        size="small"
                        name="auditor"
                        variant="outlined"
                        value={auditData.auditor}
                        onChange={(e) =>
                          handleAuditDataChange("auditor", e.target.value)
                        }
                      />
                    </Box>
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      borderLeft: "none",
                      padding: "5px",
                    }}
                  ></th>
                </tr>
                <tr>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "left",
                      padding: "5px",
                      borderRight: "none",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <b>Date</b>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          slotProps={{ textField: { size: "small" } }}
                          label="Date *"
                          inputFormat="dd/MM/yyyy"
                          format="dd/MM/yyyy"
                          value={auditData.date ? new Date(auditData.date) : ""}
                          onChange={(date) =>
                            handleAuditDataChange("date", date)
                          }
                          renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                      borderRight: "none",
                      borderLeft: "none",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <b>Auditee</b>
                      <TextField
                        sx={{ width: "60%", m: "auto" }}
                        rows={1.5}
                        multiline
                        maxRows={5}
                        size="small"
                        name="auditee"
                        variant="outlined"
                        value={auditData.auditee}
                        onChange={(e) =>
                          handleAuditDataChange("auditee", e.target.value)
                        }
                      />
                    </Box>
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                      borderLeft: "none",
                    }}
                  ></th>
                </tr>
              </thead>
            </table>
            <br />
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                fontSize: "10px",
              }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={"3"}
                    style={{
                      border: "1px solid black",
                      textAlign: "left",
                      padding: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "10px"
                      }}
                    >
                      <h6>
                        <b>Function / Activity</b>
                      </h6>
                      <FormControl sx={{ width: "80%", m: "auto" }}>
                        <InputLabel id="demo-simple-select-label">
                          Function/Activity
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={auditData.functionActivity}
                          label="Function/Activity"
                          onChange={(e) =>
                            handleAuditDataChange(
                              "functionActivity",
                              e.target.value
                            )
                          }
                        >
                          {deptList?.map((el) => (
                            <MenuItem value={el.name}>{el.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </th>
                  <th
                    colSpan={"3"}
                    style={{
                      border: "1px solid black",
                      textAlign: "right",
                      padding: "5px",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleAddRow}
                    >
                      Add
                    </Button>
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                      width: "15%",
                    }}
                  >
                    <h6>
                      <b>Cluase No.</b>
                    </h6>
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                      width: "70%",
                    }}
                  >
                    <h6>
                      <b>Records Observation</b>
                    </h6>
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                      width: "15%",
                    }}
                  >
                    <h6>
                      <b>C / NC / Min NC / OFI</b>
                    </h6>
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    <h6>
                      <b>Action</b>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                {functionRows.map((row, index) => (
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "left",
                        padding: "5px",
                      }}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        rows={2}
                        multiline
                        maxRows={5}
                        name="claueNumber"
                        value={row.claueNumber}
                        onChange={(e) =>
                          handleChange(index, "claueNumber", e.target.value)
                        }
                        variant="outlined"
                      />
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                        padding: "5px",
                      }}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        rows={2}
                        multiline
                        maxRows={5}
                        name="recordsObservation"
                        value={row.recordsObservation}
                        onChange={(e) =>
                          handleChange(
                            index,
                            "recordsObservation",
                            e.target.value
                          )
                        }
                        variant="outlined"
                      />
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                        padding: "5px",
                      }}
                    >
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{ minWidth: 90 }}
                      >
                        <InputLabel id={`select-type-label-${index}`}>
                          Select Type
                        </InputLabel>
                        <Select
                          value={row.CType}
                          onChange={(e) =>
                            handleChange(index, "CType", e.target.value)
                          }
                          label={`Type ${index}`}
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={1}>C</MenuItem>
                          <MenuItem value={2}>NC</MenuItem>
                          <MenuItem value={3}>Min NC</MenuItem>
                          <MenuItem value={4}>OFI</MenuItem>
                        </Select>
                      </FormControl>
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                        padding: "5px",
                      }}
                    >
                      <Button
                        color="error"
                        size="small"
                        onClick={() => handleDeleteRow(index)}
                      >
                        <DeleteIcon />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </div>
      )}
      {isPrinting && printView()}
      {!isPrinting && (
        <Grid container spacing={1} align="left">
          <Grid item xs={3} sm={3} md={1} sx={{ margin: "20px 0px" }}>
            <Button
              style={{ backgroundColor: "grey" }}
              variant="contained"
              size="small"
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </Button>
          </Grid>
          {params.id != 0 && (
            <Grid item xs={3} sm={3} md={1} sx={{ margin: "20px 0px" }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setisPrinting(!isPrinting);
                }}
              >
                Print
              </Button>
            </Grid>
          )}
          <Grid item xs={3} sm={3} md={1} sx={{ margin: "20px 0px" }}>
            <Button variant="contained" size="small" onClick={handleSubmit}>
              {params.id == 0 ? `Submit` : `Update`}
            </Button>
          </Grid>
        </Grid>
      )}
      {downloadPdfloading && loadingModal()}
    </div>
  );
}
